var sW, sH;
sW = window.innerWidth;
sH = window.innerHeight;
var web_main_icon = document.getElementById("web-mainIcon");
var courses = document.getElementById("courses");
if (courses) {
    var coursesClientRect = courses.getBoundingClientRect();
    var coursesY = coursesClientRect.top;
}
var crs = document.getElementById("customer_reviews");
if (crs) {
    var crsClientRect = crs.getBoundingClientRect();
    var crsY = crsClientRect.top;
}
var faqs = document.getElementById("faqs");
if (faqs) {
    var faqsClientRect = faqs.getBoundingClientRect();
    var faqsY = faqsClientRect.top;
}
// スクロールのイベントハンドラを登録
window.addEventListener('scroll', function (e) {
    if (courses) {
        if (document.documentElement.scrollTop > coursesY - (3 * sH / 4) || document.body.scrollTop > coursesY - (3 * sH / 4)) {
            courses.classList.add("effect-scroll");
            courses.classList.remove("effect-fade");
        }
    }
    if (crs) {
        if (document.documentElement.scrollTop > crsY - (3 * sH / 4) || document.body.scrollTop > crsY - (3 * sH / 4)) {
            crs.classList.add("effect-scroll");
            crs.classList.remove("effect-fade");
        }
    }
    if (faqs) {
        if (document.documentElement.scrollTop > faqsY - (3 * sH / 4) || document.body.scrollTop > faqsY - (3 * sH / 4)) {
            faqs.classList.add("effect-scroll");
            faqs.classList.remove("effect-fade");
        }
    }
});

// スムーススクロール
var Ease = {
    easeInOut: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1
  }
var duration = 500;
var smoothScrollTriggers = document.getElementsByClassName("smooth-scroll");
if (smoothScrollTriggers) {
    for (var i = 0, len = smoothScrollTriggers.length | 0; i < len; i = i + 1 | 0) {
        smoothScrollTrigger = smoothScrollTriggers[i];
        smoothScrollTrigger.addEventListener('click', function (e) {
            var href = smoothScrollTrigger.dataset.href;
            var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
            var targetElement = document.getElementById(href.replace('#', ''));
            if (targetElement) {
                e.preventDefault();
                e.stopPropagation();
                var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - 115;
                var startTime = performance.now();
                var loop = function (nowTime) {
                var time = nowTime - startTime;
                var normalizedTime = time / duration;
                if (normalizedTime < 1) {
                    window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
                    requestAnimationFrame(loop);
                } else {
                    window.scrollTo(0, targetPosition);
                }
            }
            requestAnimationFrame(loop);
        }
        });
    }
}



// flash メッセージ
var flash = document.getElementById("flashModalButton")
if (flash) {
    flash.click()
}

function calcLevel(q) {
    switch (q) {
        case "auto":
            return -1;
        case "q360":
            return 0;
        case "q720":
            return 1;
        case "q1080":
            return 2;
        default:
            return -1;
    }
}

// hls.js
var videos = document.getElementsByClassName("video-hls");
for (var i = 0, len = videos.length | 0; i < len; i = i + 1 | 0) {
    var video = videos[i];
    var videoSrc = video.dataset.src;

    if (Hls.isSupported()) {
        var hls = new Hls({
            startPosition: 0,
            xhrSetup: function (xhr, url) {
                xhr.withCredentials = true;
            }
        });
        // bind them together
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            console.log("video and hls.js are now bound together !");
            hls.loadSource(videoSrc);
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                console.log("manifest loaded, found " + data.levels.length + " quality level");
                video.muted = false;

                var radioQuolities = document.getElementsByName('quolity');
                radioQuolities.forEach(function (r) {
                    r.addEventListener("click", function () {
                        console.log("clickされました")
                        hls.nextLevel = calcLevel(document.querySelector("input:checked[name=quolity]").value);
                    });
                });

                var selectSpeed = document.getElementById("speed");
                selectSpeed.addEventListener("change", function () {
                    console.log("changeされました")
                    video.playbackRate = selectSpeed.value;
                })

                hls.on(Hls.Events.LEVEL_SWITCHED, function (level) {
                    console.log("new level is " + hls.currentLevel);
                })
            });

        });
        
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function() {
            video.play();
        });
    }
}
